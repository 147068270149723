$(document).on("turbolinks:load", () => {
  var setSetPriceButtons = (elem) => {
    var customerId = $("#service_order_customer_id").val();
    var productId = $(elem).find(".service-order-product-id").val();
    console.log(productId);

    $.ajax({
      url: "/ordering/product_prices",
      dataType: "json",
      data: {
        organization_id: customerId,
        id: productId,
      },
      success: (data, status, xhr) => {
        console.log(data);
        var setOrgPriceButton = $(elem).find(".set-org-price");
        if (data.organization_price) {
          setOrgPriceButton.data("value", data.organization_price);
          setOrgPriceButton.text("PHP " + data.organization_price);
          setOrgPriceButton.show();
        } else {
          setOrgPriceButton.data("value", null);
          setOrgPriceButton.hide();
        }

        var setPriceButton = $(elem).find(".set-price");
        if (data.price) {
          setPriceButton.data("value", data.price);
          setPriceButton.text("PHP " + data.price);
          setPriceButton.show();
        } else {
          setPriceButton.data("value", null);
          setPriceButton.hide();
        }
      },
    });
  };

  $(".form-service-order").on(
    "cocoon:after-insert",
    (e, insertedItem, originalEvenet) => {
      $(insertedItem)
        .find(".auto-select2")
        .select2({
          theme: "bootstrap4",
        })
        .on("change", (e) => {
          setSetPriceButtons(insertedItem);
        });

      setSetPriceButtons(insertedItem);
    },
  );

  $("#service_order_customer_id").on("select2:select", (e) => {
    var allowEditingOfName =
      e.params.data.record.require_service_order_customer_name;

    if (allowEditingOfName) {
      $("#service_order_customer_name").prop("readonly", false);
      $("#service_order_customer_name").val(e.params.data.record.name);
    } else {
      $("#service_order_customer_name").prop("readonly", true);
      $("#service_order_customer_name").val(e.params.data.record.name);
    }

    $("tr.service-order-item").each((index, elem) => {
      setSetPriceButtons(elem);
    });
  });

  $(".form-service-order").on("click", ".set-org-price", (evt) => {
    $(evt.target)
      .closest(".service-order-item")
      .find(".service-order-unit-price")
      .val($(evt.target).data("value"));
  });

  $(".form-service-order").on("click", ".set-price", (evt) => {
    $(evt.target)
      .closest(".service-order-item")
      .find(".service-order-unit-price")
      .val($(evt.target).data("value"));
  });

  $(".hubspot-deals-select2").select2({
    theme: "bootstrap4",
    ajax: {
      url: "/ordering/hubspot_deals.json",
      dataType: "json",
      delay: 250,
      processResults: (data) => {
        var options = $.map(data, (item) => {
          return {
            id: item.attributes.id,
            text: `${item.attributes.name} | PHP ${item.attributes.amount} | ${
              item.attributes.deal_stage
            } | Created: ${item.attributes.create_date.substring(0, 10)}`,
            record: item.attributes,
          };
        });

        options.unshift({
          id: "",
          text: "[No Deal]",
        });

        return {
          results: options,
        };
      },
    },
  });
});
